// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueItem } from "../../../models/TextValueItem";
import { AnagraficaCommerciali } from "@/models/commerciali/anagraficaCommerciali";
import { AgenzieRegistrazioneItem } from "@/models/agenzie/agenzieRegistrazioneItem";
import permessi from "@/config/permessi";
import moment from "moment";
import apiFile from "@/services/apiFile";

const _ENDPONT = api.endpoint.AGENZIE;
@Component({})
export default class agenziePage extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Anagrafica.AGENZIE]; }
	showDetail: boolean = true;
	userNumbers: number[] = [2, 3, 4];
	showOnlyActive: boolean = true;

	visible: boolean = true;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			
			{ field: "denominazione", title: this.$i18n.t("generico.denominazione").toString(), headerCell: "myHeaderCellTemplate", width: 300, filterable: true, export: true },
			{ field: "ragioneSociale", title: this.$i18n.t("generico.ragioneSociale").toString(), headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "cognome", title: this.$i18n.t("generico.cognome").toString(), headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			{ field: "piva", title: "P.Iva", headerCell: "myHeaderCellTemplate", width: 120, filterable: true, export: true, hidden:true },
			{ field: "email", title: this.$i18n.t("generico.email").toString(), headerCell: "myHeaderCellTemplate", width: 200, filterable: true, export: true },
			{ field: "pec", title: "PEC", headerCell: "myHeaderCellTemplate", width: 200, filterable: true, export: true, hidden:true },
			{ field: "tel", title: this.$i18n.t("generico.telefono").toString(), headerCell: "myHeaderCellTemplate", width: 120, filterable: true, export: true },
			{
				field: "dataRegistrazione",
				title: "Data reg.",
				width: 120,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				exportRenderFunction: this.exportRenderDate,
				headerType: "daterange"
			},
			{ field: "citta", title: "Citta", headerCell: "myHeaderCellTemplate", width: 150, filterable: true, export: true },
			{ field: "provincia", title: this.$i18n.t("generico.provincia").toString(), headerCell: "myHeaderCellTemplate", width: 90, filterable: true, export: true },
		
			{
				field: "abilitata",
				title: "Abilitata",
				width: 80,
				disabled: true,
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterType: "boolean",
				export: true
			},
			{ field: "documenti", title: "Documenti", headerCell: "myHeaderCellTemplate", cell:"documentCellTemplate", filterable: false, export: false },
		];
	}

	created() {
		var self = this;
		if( self.showOnlyActive){
			self.filter.filters.push({
				field: "dataAbilitazione",
				operator: "eq",
				value: "!!date=null"
			});
		}
		
		self.setDefaultPageSize();
		self.getData();
	}

	mounted() { }

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "dataAbilitazione";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!date=null"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function (item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	inviaDocumento(item: AgenzieRegistrazioneItem) {
		var self = this;
		if (item.itemID > 0) {
			let message = self.$createElement("div", {
				domProps: { innerHTML: `<h5>Invia Documento: ${item.ragioneSociale.replace("_", " - ")}</h5>${self.$i18n.t("messaggio.confermaInvioDocumento")}` }
			});
			let title = this.$i18n.t("titolo.confermaInvioDocumento").toString();

			self.$bvModal
				.msgBoxConfirm([message], {
					title: title,
					okVariant: "warning",
					okTitle: self.$i18n
						.t("btn.si")
						.toString()
						.toUpperCase(),
					cancelTitle: self.$i18n
						.t("btn.no")
						.toString()
						.toUpperCase(),
					cancelVariant: "outline-theme",
					footerClass: "",
					headerBgVariant: "warning"
				})
				.then(value => {
					if (value) {

						api.inviaDocumentoAgenzia(item.itemID)
							.then(res => {
								item.dataInvioDocumenti = res.data.dataInvioDocumenti;
								item.contatoreInvioDocumenti = res.data.contatoreInvioDocumenti;
								this.$bvToast.toast(this.$i18n.t("generico.documentoInviato").toString(), {
									variant: "success",
									title: this.$i18n.t("generico.azioneeseguita").toString(),
									solid: true
								});
							})
							.catch(err => {
								console.log(err);
							});
					}
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noItemSelected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	formattaData(data) {
		if(!data) return '';
		else return moment(data).format("DD/MM/YYYY HH:mm");
	}

	abilitaUtentiAgenzia(item: AgenzieRegistrazioneItem) {
		var self = this;
		if (item.itemID > 0) {
			let message = self.$createElement("div", {
				domProps: { innerHTML: `<h5>Abilitazione Utenti: ${item.ragioneSociale.replace("_", " - ")}</h5>${self.$i18n.t("messaggio.abilitaAgenzia")}` }
			});
			let title = this.$i18n.t("titolo.confermaAbilitazioneUtenti").toString();

			self.$bvModal
				.msgBoxConfirm([message], {
					title: title,
					okVariant: "warning",
					okTitle: self.$i18n
						.t("btn.si")
						.toString()
						.toUpperCase(),
					cancelTitle: self.$i18n
						.t("btn.no")
						.toString()
						.toUpperCase(),
					cancelVariant: "outline-theme",
					footerClass: "",
					headerBgVariant: "warning"
				})
				.then(value => {
					if (value) {

						api.abilitaUtentiAgenzia(item.itemID)
							.then(res => {
								item.dataAbilitazione = new Date();
								this.$bvToast.toast(this.$i18n.t("generico.utentiAbilitati").toString(), {
									variant: "success",
									title: this.$i18n.t("generico.azioneeseguita").toString(),
									solid: true
								});
							})
							.catch(err => {
								console.log(err);
							});
					}
				})
				.catch(err => {
					console.log(err);
				});
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noItemSelected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}

	downloadVisuraCamerale(item: AgenzieRegistrazioneItem) {
		var self = this;
		
		apiFile
			.downloadFileRegistrazioneAgenzia(item.visuraCamerale)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = this.$i18n.t('generico.visuraCamerale') + '.' + item.visuraCamerale.split('.').pop();
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
	downloadAutorizzazione(item: AgenzieRegistrazioneItem) {
		var self = this;
		
		apiFile
			.downloadFileRegistrazioneAgenzia(item.docEsercizioAttivita)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = this.$i18n.t('generico.autorizzazioneEsercizioAttivita') + '.' + item.docEsercizioAttivita.split('.').pop();
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
	downloadCodiceFiscale(item: AgenzieRegistrazioneItem) {
		var self = this;
		
		apiFile
			.downloadFileRegistrazioneAgenzia(item.docCodiceFiscale)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = this.$i18n.t('generico.codiceFiscale') + '.' + item.docCodiceFiscale.split('.').pop();
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
	downloadCartaIdentita(item: AgenzieRegistrazioneItem) {
		var self = this;
		
		apiFile
			.downloadFileRegistrazioneAgenzia(item.docCartaIdentita)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = this.$i18n.t('generico.cartaidentita') + '.' + item.docCartaIdentita.split('.').pop();
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	get detailTemplate() {
		if (this.showDetail) return "detailTemplate";
		else return "";
	}
}
